import React from 'react';
import { CalculationContext } from '../../../../context/CalculationContext';
import PropTypes from 'prop-types';
import Accordion from '../../../Accordion';
import NumberInput from '../components/NumberInput';

const AccordionInner = (props) => {
    return (
        <Accordion level={2} label={props.label}>
            <div className='calculator-result__dialog-additional-settings__energy-cost'>
                {props.children}
            </div>
        </Accordion>
    );
};

AccordionInner.props = {
    label: PropTypes.string,
    children: PropTypes.element
};

const AdvancedSettings = ({ props }) => {
    const calculationContext = React.useContext(CalculationContext);

    return (
        <Accordion level={1} label={props.translations.advancedSettings}>

            <AccordionInner label={props.translations.requestedEnergyUsageLabel} >

                {/* Energy Use Per Square Meter */}
                <NumberInput
                    label={calculationContext.newBuilding ? props.translations.energyUseNewLabel : props.translations.energyUseExistingLabel}
                    onChange={calculationContext.setEnergyUsePerSquareMeter}
                    value={calculationContext.energyUsePerSquareMeter}
                    suffix={props.translations.kwhSqmLabel} />

            </AccordionInner>

            <AccordionInner label={props.translations.energyDivisionLabel} >

                {/* Electricity Cost */}
                <NumberInput
                    label={props.translations.electricityLabel}
                    onChange={calculationContext.setEnergyCostElectricity}
                    value={calculationContext.energyCostElectricity}
                    suffix={`${calculationContext.currency}/${props.translations.locKwhLabel}`}
                />

                {/* Gas Cost */}
                <NumberInput
                    label={props.translations.gasLabel}
                    onChange={calculationContext.setEnergyCostGas}
                    value={calculationContext.energyCostGas}
                    suffix={`${calculationContext.currency}/${props.translations.locKwhLabel}`}
                />

                {/* Oil Cost */}
                <NumberInput
                    label={props.translations.oilLabel}
                    onChange={calculationContext.setEnergyCostOil}
                    value={calculationContext.energyCostOil}
                    suffix={`${calculationContext.currency}/${props.translations.locKwhLabel}`}
                />

                {/* District Heating Cost */}
                <NumberInput
                    label={props.translations.districtHeatingLabel}
                    onChange={calculationContext.setEnergyCostDistrictHeating}
                    value={calculationContext.energyCostDistrictHeating}
                    suffix={`${calculationContext.currency}/${props.translations.locKwhLabel}`}
                />

                {/* District Cooling Cost */}
                <NumberInput
                    label={props.translations.districtCoolingLabel}
                    onChange={calculationContext.setEnergyCostDistrictCooling}
                    value={calculationContext.energyCostDistrictCooling}
                    suffix={`${calculationContext.currency}/${props.translations.locKwhLabel}`}
                />

            </AccordionInner>

            <AccordionInner label={props.translations.energyEfficiencyFactorLabel} >

                {/* Heating Production Efficiency */}
                <NumberInput
                    label={props.translations.heatingLabel}
                    onChange={calculationContext.setHeatingProductionEfficiency}
                    value={calculationContext.heatingProductionEfficiency}
                    suffix={props.translations.kilowattPerKilowatt}
                />

                {/* Cooling Production Efficiency */}
                <NumberInput
                    label={props.translations.coolingLabel}
                    onChange={calculationContext.setCoolingProductionEfficiency}
                    value={calculationContext.coolingProductionEfficiency}
                    suffix={props.translations.kilowattPerKilowatt}
                />

                {/* Domestic Hot Water Production Efficiency */}
                <NumberInput
                    label={props.translations.domesticHotWaterLabel}
                    onChange={calculationContext.setHotWaterProductionEfficiency}
                    value={calculationContext.hotwaterProductionEfficiency}
                    suffix={props.translations.kilowattPerKilowatt}
                />
            </AccordionInner>

            <AccordionInner label={props.translations.greenHouseGasEquivalentLabel} >
                {/* Electricity GHG CO2 */}
                <NumberInput
                    label={props.translations.electricityLabel}
                    onChange={calculationContext.setGHGCo2Electricity}
                    value={calculationContext.GreenhouseGasCo2Electricity}
                    suffix={props.translations.kCo2eLabel}
                />

                {/* Gas GHG CO2 */}
                <NumberInput
                    label={props.translations.gasLabel}
                    onChange={calculationContext.setGHGCo2Gas}
                    value={calculationContext.GreenhouseGasCo2Gas}
                    suffix={props.translations.kCo2eLabel}
                />

                {/* Oil GHG CO2 */}
                <NumberInput
                    label={props.translations.oilLabel}
                    onChange={calculationContext.setGHGCo2Oil}
                    value={calculationContext.GreenhouseGasCo2Oil}
                    suffix={props.translations.kCo2eLabel}
                />

                {/* District Heating GHG CO2 */}
                <NumberInput
                    label={props.translations.districtHeatingLabel}
                    onChange={calculationContext.setGHGDistrictHeating}
                    value={calculationContext.GreenhouseGasDistrictHeating}
                    suffix={props.translations.kCo2eLabel}
                />

                {/* District Cooling GHG CO2 */}
                <NumberInput
                    label={props.translations.districtCoolingLabel}
                    onChange={calculationContext.setGHGDistrictCooling}
                    value={calculationContext.GreenhouseGasDistrictCooling}
                    suffix={props.translations.kCo2eLabel}
                />
            </AccordionInner>

            <AccordionInner label={props.translations.energyDivisionLabel} >
                {/* Heating Division Percentage */}
                <NumberInput
                    label={props.translations.energyDivisionHeatingLabel}
                    onChange={calculationContext.setHeatingDivisionPercentage}
                    value={calculationContext.heatingDivisionPercentage}
                    suffix="%"
                />

                {/* Cooling Division Percentage */}
                <NumberInput
                    label={props.translations.energyDivisionCoolingLabel}
                    onChange={calculationContext.setCoolingDivisionPercentage}
                    value={calculationContext.coolingDivisionPercentage}
                    suffix="%"
                />

                {/* Domestic Hot Water Division Percentage */}
                <NumberInput
                    label={props.translations.energyDivisionHotWaterLabel}
                    onChange={calculationContext.setDomesticHotWaterDivisionPercentage}
                    value={calculationContext.domesticHotWaterDivisionPercentage}
                    suffix="%"
                />

                {/* Building Electricity Division Percentage */}
                <NumberInput
                    label={props.translations.energyDivisionBuildingElectricityLabel}
                    onChange={calculationContext.setBuildingElectricityDivisionPercentage}
                    value={calculationContext.buildingElectricityDivisionPercentage}
                    suffix="%"
                />

            </AccordionInner>

        </Accordion >
    );
};

AdvancedSettings.props = {
    translations: PropTypes.any
};

export default AdvancedSettings;
