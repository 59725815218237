import React from 'react';
import PropTypes from 'prop-types';

const NumberInput = (props) => {
    const handleNumberInput = (e) => {
        const value = e.target.value;

        // Only update if the input is a valid number
        if (!isNaN(value) && value.trim() !== '') {
            if (props.onChange) {
                props.onChange(Number(value));
            }
        }
    };

    return (
        <div className='energy-cost__row'>
            <p>{props.label}</p>
            <input type="number" onChange={handleNumberInput} value={props.value} />
            <p>{props.suffix}</p>
        </div>
    );
};

NumberInput.props = {
    id: PropTypes.string,
    suffix: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.element,
    onChange: PropTypes.func
};

export default NumberInput;
