import React, { useState } from 'react';

export const CalculationContext = React.createContext({
    initialResponse: null,
    setInitialResponse: () => null,
    lang: '',
    setLang: () => null,
    endpoint: '',
    setEndpoint: () => null,
    country: '',
    setCountry: () => null,
    application: '',
    setApplication: () => null,
    sizeOfBuilding: '',
    setSizeOfBuilding: () => null,
    newBuilding: '',
    setNewBuilding: () => null,
    currency: '',
    setCurrency: () => null,
    energyUsePerSquareMeter: '',
    setEnergyUsePerSquareMeter: () => null,
    heatingType: '',
    setHeatingType: () => null,
    heatingProductionEfficiency: '',
    setHeatingProductionEfficiency: () => null,
    hotwaterProduction: '',
    setHotWaterProduction: () => null,
    hotwaterProductionEfficiency: '',
    setHotWaterProductionEfficiency: () => null,
    coolingProduction: '',
    setCoolingProduction: () => null,
    coolingProductionEfficiency: '',
    setCoolingProductionEfficiency: () => null,
    energyCostElectricity: '',
    setEnergyCostElectricity: () => null,
    energyCostGas: '',
    setEnergyCostGas: () => null,
    energyCostOil: '',
    setEnergyCostOil: () => null,
    energyCostDistrictHeating: '',
    setEnergyCostDistrictHeating: () => null,
    energyCostDistrictCooling: '',
    setEnergyCostDistrictCooling: () => null,
    GreenhouseGasCo2Gas: '',
    setGHGCo2Gas: () => null,
    GreenhouseGasCo2Oil: '',
    setGHGCo2Oil: () => null,
    GreenhouseGasDistrictHeating: '',
    setGHGDistrictHeating: () => null,
    GreenhouseGasDistrictCooling: '',
    setGHGDistrictCooling: () => null,
    GreenhouseGasCo2Electricity: '',
    setGHGCo2Electricity: () => null,
    heatingDivisionPercentage: '',
    setHeatingDivisionPercentage: () => null,
    coolingDivisionPercentage: '',
    setCoolingDivisionPercentage: () => null,
    domesticHotWaterDivisionPercentage: '',
    setDomesticHotWaterDivisionPercentage: () => null,
    buildingElectricityDivisionPercentage: '',
    setBuildingElectricityDivisionPercentage: () => null,
    chillerHeatpump: null,
    setChillerHeatpump: () => null,
    hydronicOptimisation: null,
    setHydronicOptimisation: () => null,
    waterflowOptimisation: null,
    setWaterflowOptimisation: () => null,
    airHandlingUnitWithBuiltInControls: null,
    setAirHandlingUnitWithBuiltInControls: () => null,
    aHUWatertempOptimisation: null,
    setAHUWatertempOptimisation: () => null,
    dCVAirOptimisation: null,
    setDCVAirOptimisation: () => null,
    dCVWatertempOptimisationRoom: null,
    setDCVWatertempOptimisationRoom: () => null,
    analyticsandVisualisation: null,
    setAnalyticsandVisualisation: () => null,
    calculate: () => null,
    calculation: null,
    Co2Savings: 0,
    Co2SavingsPercentage: 0,
    ElectricEnergySavings: 0,
    ElectricalEnergyUsage: 0,
    EnergyConsumption: 0,
    EnergySavingsPercentage: 0,
    FinancialSavings: 0,
    FinancialSavingsPercentage: 0,
    OperationalCarbonFootprint: 0,
    OperationalCost: 0,
    ThermalEnergySavings: 0,
    ThermalEnergyUsage: 0,
    TotalEnergySavings: 0
});

const CalculationContextProvider = (props) => {

    const [initialResponse, setInitialResponse] = React.useState(null);
    const [lang, setLang] = React.useState('sv');
    const [endpoint, setEndpoint] = React.useState('');

    const [country, setCountry] = React.useState('');
    const [application, setApplication] = React.useState('');
    const [heatingType, setHeatingType] = React.useState('');
    const [hotwaterProduction, setHotWaterProduction] = React.useState('');
    const [coolingProduction, setCoolingProduction] = React.useState('');

    const [currency, setCurrency] = React.useState('');

    const [sizeOfBuilding, setSizeOfBuilding] = useState(0);
    const [newBuilding, setNewBuilding] = useState(true);
    const [energyUsePerSquareMeter, setEnergyUsePerSquareMeter] = useState(0);
    const [heatingProductionEfficiency, setHeatingProductionEfficiency] = useState(0);
    const [hotwaterProductionEfficiency, setHotWaterProductionEfficiency] = useState(0);
    const [coolingProductionEfficiency, setCoolingProductionEfficiency] = useState(0);
    const [energyCostElectricity, setEnergyCostElectricity] = useState(0);
    const [energyCostGas, setEnergyCostGas] = useState(0);
    const [energyCostOil, setEnergyCostOil] = useState(0);
    const [energyCostDistrictHeating, setEnergyCostDistrictHeating] = useState(0);
    const [energyCostDistrictCooling, setEnergyCostDistrictCooling] = useState(0);
    const [GreenhouseGasCo2Gas, setGHGCo2Gas] = useState(0);
    const [GreenhouseGasCo2Oil, setGHGCo2Oil] = useState(0);
    const [GreenhouseGasDistrictHeating, setGHGDistrictHeating] = useState(0);
    const [GreenhouseGasDistrictCooling, setGHGDistrictCooling] = useState(0);
    const [GreenhouseGasCo2Electricity, setGHGCo2Electricity] = useState(0);
    const [heatingDivisionPercentage, setHeatingDivisionPercentage] = useState(0);
    const [coolingDivisionPercentage, setCoolingDivisionPercentage] = useState(0);
    const [domesticHotWaterDivisionPercentage, setDomesticHotWaterDivisionPercentage] = useState(0);
    const [buildingElectricityDivisionPercentage, setBuildingElectricityDivisionPercentage] = useState(0);
    const [chillerHeatpump, setChillerHeatpump] = useState(true);
    const [hydronicOptimisation, setHydronicOptimisation] = useState(true);
    const [waterflowOptimisation, setWaterflowOptimisation] = useState(true);
    const [airHandlingUnitWithBuiltInControls, setAirHandlingUnitWithBuiltInControls] = useState(true);
    const [aHUWatertempOptimisation, setAHUWatertempOptimisation] = useState(true);
    const [dCVAirOptimisation, setDCVAirOptimisation] = useState(true);
    const [dCVWatertempOptimisationRoom, setDCVWatertempOptimisationRoom] = useState(true);
    const [analyticsandVisualisation, setAnalyticsandVisualisation] = useState(true);

    const [energyUseSqMetersNew, setEnergyUseSqMetersNew] = React.useState(0);
    const [energyUseSqMetersExisting, setEnergyUseSqMetersExisting] = React.useState(0);

    const [Co2Savings, setCo2Savings] = useState(0);
    const [Co2SavingsPercentage, setCo2SavingsPercentage] = useState(0);
    const [ElectricEnergySavings, setElectricEnergySavings] = useState(0);
    const [ElectricalEnergyUsage, setElectricalEnergyUsage] = useState(0);
    const [EnergyConsumption, setEnergyConsumption] = useState(0);
    const [EnergySavingsPercentage, setEnergySavingsPercentage] = useState(0);
    const [FinancialSavings, setFinancialSavings] = useState(0);
    const [FinancialSavingsPercentage, setFinancialSavingsPercentage] = useState(0);
    const [OperationalCarbonFootprint, setOperationalCarbonFootprint] = useState(0);
    const [OperationalCost, setOperationalCost] = useState(0);
    const [ThermalEnergySavings, setThermalEnergySavings] = useState(0);
    const [ThermalEnergyUsage, setThermalEnergyUsage] = useState(0);
    const [TotalEnergySavings, setTotalEnergySavings] = useState(0);

    const [calculation, setCalculation] = useState();

    const calculate = async () => {

        const body = {

            'country': country,
            'application': application,
            'newBuilding': newBuilding,
            'sizeOfBuilding': sizeOfBuilding,
            'energyUsePerSquareMeter': energyUsePerSquareMeter,
            'heatingType': heatingType,
            'heatingProductionEfficiency': heatingProductionEfficiency,
            'hotwaterProduction': hotwaterProduction,
            'hotwaterProductionEfficiency': hotwaterProductionEfficiency,
            'coolingProduction': coolingProduction,
            'coolingProductionEfficiency': coolingProductionEfficiency,
            'energyCostElectricity': energyCostElectricity,
            'energyCostGas': energyCostGas,
            'energyCostOil': energyCostOil,
            'energyCostDistrictHeating': energyCostDistrictHeating,
            'energyCostDistrictCooling': energyCostDistrictCooling,
            'GreenhouseGasCo2Gas': GreenhouseGasCo2Gas,
            'GreenhouseGasCo2Oil': GreenhouseGasCo2Oil,
            'GreenhouseGasDistrictHeating': GreenhouseGasDistrictHeating,
            'GreenhouseGasDistrictCooling': GreenhouseGasDistrictCooling,
            'GreenhouseGasCo2Electricity': GreenhouseGasCo2Electricity,
            'heatingDivisionPercentage': heatingDivisionPercentage,
            'coolingDivisionPercentage': coolingDivisionPercentage,
            'domesticHotWaterDivisionPercentage': domesticHotWaterDivisionPercentage,
            'buildingElectricityDivisionPercentage': buildingElectricityDivisionPercentage,
            'chillerHeatpump': chillerHeatpump,
            'hydronicOptimisation': hydronicOptimisation,
            'waterflowOptimisation': waterflowOptimisation,
            'airHandlingUnitWithBuiltInControls': airHandlingUnitWithBuiltInControls,
            'aHUWatertempOptimisation': aHUWatertempOptimisation,
            'dCVAirOptimisation': dCVAirOptimisation,
            'dCVWatertempOptimisationRoom': dCVWatertempOptimisationRoom,
            'analyticsandVisualisation': analyticsandVisualisation
        };

        let opts = {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': lang
            },
            body: JSON.stringify(
                body)
        };

        const r = await fetch(`${endpoint}/api/energy/calculate`, opts);
        const data = await r.json();

        setCalculation(data);

    };

    const fetchParams = async () => {
        if (country && application && heatingType && hotwaterProduction && coolingProduction) {

            let opts = {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': lang
                },
                body: JSON.stringify(
                    {
                        'country': country,
                        'application': application,
                        'heatingType': heatingType,
                        'hotWaterProduction': hotwaterProduction,
                        'coolingProduction': coolingProduction
                    })
            };

            const r = await fetch(`${endpoint}/api/energy/parameters`, opts);
            const data = await r.json();

            if (data) {
                setHeatingType(data.HeatingType);
                setHotWaterProduction(data.HotWaterProduction);
                setCoolingProduction(data.CoolingProduction);
                setHeatingProductionEfficiency(data.HeatingProductionEfficiency);
                setHotWaterProductionEfficiency(data.HotWaterProductionEfficiency);
                setCoolingProductionEfficiency(data.CoolingProductionEfficiency);
                setEnergyCostElectricity(data.EnergyCostElectricity);
                setEnergyCostGas(data.EnergyCostGas);
                setEnergyCostOil(data.EnergyCostOil);
                setEnergyCostDistrictHeating(data.EnergyCostDistrictHeating);
                setEnergyCostDistrictCooling(data.EnergyCostDistrictCooling);
                setGHGCo2Gas(data.GreenhouseGasCo2Gas);
                setGHGCo2Oil(data.GreenhouseGasCo2Oil);
                setGHGDistrictHeating(data.GreenhouseGasDistrictHeating);
                setGHGDistrictCooling(data.GreenhouseGasDistrictCooling);
                setGHGCo2Electricity(data.GreenhouseGasCo2Electricity);
                setHeatingDivisionPercentage(data.HeatingDivisionPercentage);
                setCoolingDivisionPercentage(data.CoolingDivisionPercentage);
                setDomesticHotWaterDivisionPercentage(data.DomesticHotWaterDivisionPercentage);
                setBuildingElectricityDivisionPercentage(data.BuildingElectricityDivisionPercentage);

            }
        }
    };

    React.useEffect(() => {
        if (calculation) {
            setCo2Savings(calculation.Co2Savings);
            setCo2SavingsPercentage(calculation.Co2SavingsPercentage);
            setElectricEnergySavings(calculation.ElectricEnergySavings);
            setElectricalEnergyUsage(calculation.ElectricalEnergyUsage);
            setEnergyConsumption(calculation.EnergyConsumption);
            setEnergySavingsPercentage(calculation.EnergySavingsPercentage);
            setFinancialSavings(calculation.FinancialSavings);
            setFinancialSavingsPercentage(calculation.FinancialSavingsPercentage);
            setOperationalCarbonFootprint(calculation.OperationalCarbonFootprint);
            setOperationalCost(calculation.OperationalCost);
            setThermalEnergySavings(calculation.ThermalEnergySavings);
            setThermalEnergyUsage(calculation.ThermalEnergyUsage);
            setTotalEnergySavings(calculation.TotalEnergySavings);
            setCurrency(calculation.CurrencyCode);
        }
    }, [calculation]);

    React.useEffect(() => {
        fetchParams();
    }, [country, application, heatingType, hotwaterProduction, coolingProduction]);

    React.useEffect(() => {
        if (calculation)
            setEnergyUsePerSquareMeter(newBuilding ? energyUseSqMetersNew : energyUseSqMetersExisting);
    }, [newBuilding]);

    return (
        <CalculationContext.Provider value={{
            initialResponse,
            setInitialResponse,
            lang,
            setLang,
            endpoint,
            setEndpoint,
            country,
            setCountry,
            application,
            setApplication,
            sizeOfBuilding,
            setSizeOfBuilding,
            newBuilding,
            setNewBuilding,
            currency,
            setCurrency,
            energyUsePerSquareMeter,
            setEnergyUsePerSquareMeter,
            heatingType,
            setHeatingType,
            heatingProductionEfficiency,
            setHeatingProductionEfficiency,
            hotwaterProduction,
            setHotWaterProduction,
            hotwaterProductionEfficiency,
            setHotWaterProductionEfficiency,
            coolingProduction,
            setCoolingProduction,
            coolingProductionEfficiency,
            setCoolingProductionEfficiency,
            energyCostElectricity,
            setEnergyCostElectricity,
            energyCostGas,
            setEnergyCostGas,
            energyCostOil,
            setEnergyCostOil,
            energyCostDistrictHeating,
            setEnergyCostDistrictHeating,
            energyCostDistrictCooling,
            setEnergyCostDistrictCooling,
            GreenhouseGasCo2Gas,
            setGHGCo2Gas,
            GreenhouseGasCo2Oil,
            setGHGCo2Oil,
            GreenhouseGasDistrictHeating,
            setGHGDistrictHeating,
            GreenhouseGasDistrictCooling,
            setGHGDistrictCooling,
            GreenhouseGasCo2Electricity,
            setGHGCo2Electricity,
            heatingDivisionPercentage,
            setHeatingDivisionPercentage,
            coolingDivisionPercentage,
            setCoolingDivisionPercentage,
            domesticHotWaterDivisionPercentage,
            setDomesticHotWaterDivisionPercentage,
            buildingElectricityDivisionPercentage,
            setBuildingElectricityDivisionPercentage,
            chillerHeatpump,
            setChillerHeatpump,
            hydronicOptimisation,
            setHydronicOptimisation,
            waterflowOptimisation,
            setWaterflowOptimisation,
            airHandlingUnitWithBuiltInControls,
            setAirHandlingUnitWithBuiltInControls,
            aHUWatertempOptimisation,
            setAHUWatertempOptimisation,
            dCVAirOptimisation,
            setDCVAirOptimisation,
            dCVWatertempOptimisationRoom,
            setDCVWatertempOptimisationRoom,
            analyticsandVisualisation,
            setAnalyticsandVisualisation,
            calculate,
            calculation,
            energyUseSqMetersNew,
            setEnergyUseSqMetersNew,
            energyUseSqMetersExisting,
            setEnergyUseSqMetersExisting,
            Co2Savings,
            Co2SavingsPercentage,
            ElectricEnergySavings,
            ElectricalEnergyUsage,
            EnergyConsumption,
            EnergySavingsPercentage,
            FinancialSavings,
            FinancialSavingsPercentage,
            OperationalCarbonFootprint,
            OperationalCost,
            ThermalEnergySavings,
            ThermalEnergyUsage,
            TotalEnergySavings
        }}>
            {props.children}
        </CalculationContext.Provider>
    );
};

export default CalculationContextProvider;
