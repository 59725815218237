import React from 'react';
import PropTypes from 'prop-types';

const Input = (props) => {
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange(e.target.value); // Call the onChange prop with the new state
        }

    };
    return (
        <div className="form-item form-item--select ">
            <label for={props.id} className="form-item__label">
                {props.label}
            </label>
            <select id={props.id} onChange={handleChange} className="select__select " name="Select" >
                {props.children}
            </select>

        </div>
    );
};

Input.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    showPleaseSelect: PropTypes.bool,
    children: PropTypes.element,
    onChange: PropTypes.func
};

export default Input;
