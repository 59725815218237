import getEvent from '../../functions/getEvent';
import ShowInfo from '../show-info/ShowInfo';
import OptionsLimiter from '../options-limiter/OptionsLimiter';
import Modal from '../modal/Modal';
import { debounce } from 'lodash';

class Filters {
    constructor(el) {
        this.el = el;
        this.filterDropdownsSelector = '.js-filter[data-type="select"]';
        this.searchFieldSelector = '.js-filter[data-type="search"]';
        this.filterDropdownsMultipleSelector = '.js-filter[data-type="select-multiple"]';
        this.notFilterDropdownsSelector = '.js-filter:not([data-type="select"]):not([data-type="search"]):not([data-type="checkbox"])';
        this.filterCheckboxSelector = '.js-filter[data-type="checkbox"]';
        this.filterRangeSlider = '.js-filter[data-type="range-slider"]';
        this.filters = el.querySelectorAll('.js-filter');
        this.dropdownsMultiple = el.querySelectorAll(this.filterDropdownsMultipleSelector);
        this.dropdownsHolder = el.querySelector('.js-dropdowns-holder');
        this.sortableCells = el.querySelectorAll('.js-sortable-cell');
        this.loadMoreBtn = el.querySelector('.js-load-more');
        this.clearFiltersBtn = el.querySelector('.js-clear-filters');
        this.searchField = el.querySelector(this.searchFieldSelector);
        this.searchButton = this.searchField ? this.searchField.nextElementSibling : null;
        this.updateFilters = this.updateFilters.bind(this);
        this.triggerUpdate = this.triggerUpdate.bind(this);
        const savedFiltersArray = sessionStorage.getItem('filters array');
        const savedFiltersDictionary = sessionStorage.getItem('filters dictionary');
        this.filtersArray = savedFiltersArray ? JSON.parse(savedFiltersArray) : [];
        this.filtersDictionary = savedFiltersDictionary ? JSON.parse(savedFiltersDictionary) : {};
        this.sortOrder = '';
        this.sortBy = '';

        this.init();
    }

    init() {
        this.attachListeners(true);
    }

    attachListeners() {
        const self = this;

        if (this.clearFiltersBtn) {
            this.clearFiltersBtn.addEventListener('click', (e) => {
                e.preventDefault();
                this.clearFilters();
            });
        };
        this.el.addEventListener('change', function (e) {
            const target = e.target;
            if (target.matches
                ? target.matches(self.filterDropdownsSelector)
                : target.msMatchesSelector(self.filterDropdownsSelector)
            ) {
                self.updateFilter(target, true);
            }
        }, false);
        this.el.addEventListener('change', function (e) {
            const target = e.target;
            const shouldUpdateFromData = target.dataset.shouldUpdateFilters;
            const shouldUpdate = typeof shouldUpdateFromData !== 'undefined' ? shouldUpdateFromData === 'true' : true;
            if (target.matches
                ? target.matches(self.filterCheckboxSelector)
                : target.msMatchesSelector(self.filterCheckboxSelector)
            ) {
                self.updateFilter(target, shouldUpdate);
            }
        }, false);
        this.el.addEventListener('change', function (e) {
            const target = e.target.closest(self.filterRangeSlider);

            if (target) {
                const shouldUpdateFromData = target.dataset.shouldUpdateFilters;
                const shouldUpdate = typeof shouldUpdateFromData !== 'undefined' ? shouldUpdateFromData === 'true' : true;
                self.updateFilter(target, shouldUpdate);
            }
        }, false);

        this.el.addEventListener('click', function (e) {
            const target = e.target;
            if (target.matches
                ? target.matches(self.notFilterDropdownsSelector)
                : target.msMatchesSelector(self.notFilterDropdownsSelector)
            ) {
                self.updateFilter(target, true);
            }
        }, false);

        for (let i = 0; this.dropdownsMultiple.length > i; i++) {
            this.dropdownsMultiple[i].addEventListener('change', (e) => {
                const target = e.target;
                if (target.matches
                    ? target.matches(this.filterDropdownsMultipleSelector)
                    : target.msMatchesSelector(this.filterDropdownsMultipleSelector)
                ) {
                    this.updateFilter(target, true);
                }
            });
        }

        for (let i = 0; i < this.sortableCells.length; i++) {
            const cell = this.sortableCells[i];
            cell.addEventListener('click', e => this.sortTable(e, cell));
        }

        if (this.searchField) {
            this.searchField.addEventListener('input', (e) => {
                const target = e.target;
                this.updateFilterDebounced(target, true);
            });
        }

        if (this.searchButton) {
            this.searchButton.addEventListener('click', (e) => {
                this.updateFilter(this.searchField, true);
            });
        }

        window.addEventListener('loadMoreUpdate', (e) => {
            if (e && e.data) {
                const showInfoEls = this.el.querySelectorAll('.js-show-info');

                // Re-initialize modals
                const els = document.querySelectorAll('.modal');

                for (let el of els) {
                    new Modal(el);
                }
                // Re-initialize showInfo component
                for (let el of showInfoEls) {
                    ShowInfo(el);
                }

                // Re-initialize Options limiter component
                const optionsLimiter = this.el.querySelectorAll('.js-options-limiter');

                for (let el of optionsLimiter) {
                    new OptionsLimiter(el);
                }
            }
        });

        this.loadMoreBtn && this.loadMoreBtn.addEventListener('updateFilterDropdowns', this.updateFilters);
    }

    sortTable(e, cell) {
        const newSortBy = cell.id;
        if (newSortBy === this.sortBy) {
            if (this.sortOrder === 'asc') {
                this.sortOrder = 'desc';
            } else {
                this.sortOrder = 'asc';
            }
        } else {
            this.sortOrder = 'asc';
        }
        this.sortBy = newSortBy;
        this.triggerUpdateDebounced(cell.getAttribute('aria-controls'));
        e.preventDefault();
    }

    groupFilters(filters) {
        let a = [];

        for (let filter of filters) {
            const existingIndex = a.findIndex((obj) => obj.name === filter.name);

            if (existingIndex > -1) {
                a[existingIndex].value = a[existingIndex].value.concat(filter.value);
            } else {
                filter.value = [filter.value];
                a.push(filter);
            }
        }

        return a;
    }

    updateFilters(e) {
        const { subcategoriesMarkup } = e.data;
        if (this.dropdownsHolder) {
            this.dropdownsHolder.innerHTML = subcategoriesMarkup;
        }
    }

    triggerUpdate(ariaControls) {
        const event = getEvent('filterApplied');
        console.log('filtersDictionary', this.filtersDictionary);
        const data = {
            ariaControls,
            filtersArray: this.groupFilters(this.filtersArray),
            filtersDictionary: this.filtersDictionary,
            sortData: {}
        };
        if (this.sortBy) {
            data.sortData.sortOrder = this.sortOrder;
            data.sortData.sortBy = this.sortBy;
        }
        event['data'] = data;

        if (this.loadMoreBtn) {
            this.loadMoreBtn.dispatchEvent(event);
        }
    }

    triggerUpdateDebounced = debounce(this.triggerUpdate, 100, false);

    clearFilters() {
        const filters = this.el.querySelectorAll('.js-filter');
        let ariaControls = '';

        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i];
            const filterType = filter.getAttribute('data-type-variant') || filter.getAttribute('data-type');

            switch (filterType) {
                case 'switch':
                    filter.checked = false;
                    break;
                case 'select-multiple': {
                    const checked = filter.querySelectorAll('option:checked');
                    [...checked].forEach(option => { option.selected = false; });
                    filter.value = '';
                    filter.dispatchEvent(new Event('change'));
                    break;
                }
                case 'select-with-relation':
                    filter.value = '';
                    break;
                case 'select':
                    filter.value = '';
                    break;
                case 'checkbox':
                    filter.checked = false;
                    filter.dispatchEvent(new Event('change'));
                    break;
                case 'search':
                    filter.value = '';
                    break;
                case 'range-slider':
                    filter.dispatchEvent(new Event('reset'));
                    filter.dispatchEvent(new Event('change'));
                    break;
                default:
                    break;
            }
            ariaControls = filter.getAttribute('aria-controls');
        }

        this.updateFilter(null, false);
        sessionStorage.removeItem('filters array');
        sessionStorage.removeItem('filters dictionary');
        this.triggerUpdateDebounced(ariaControls);
    }

    updateFilter(filter, shouldUpdate) {
        this.filtersArray = [];
        this.filtersDictionary = {};

        const filters = this.el.querySelectorAll('.js-filter');
        const dropdownFilters = this.el.querySelectorAll('.js-filter[data-type="select"]');
        const changedDropdownIndex = Array.prototype.indexOf.call(dropdownFilters, filter);

        let currentDropdownIndex = 0;

        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i];
            const filterType = filter.getAttribute('data-type-variant') || filter.getAttribute('data-type');

            switch (filterType) {
                case 'switch':
                    this.filtersDictionary[filter.value] = filter.checked;
                    break;
                case 'select-multiple': {
                    const checked = filter.querySelectorAll('option:checked');
                    const checkedValues = [...checked].map(option => option.value);

                    if (checkedValues.length) {
                        this.filtersDictionary[filter.name] = checkedValues.join('|');
                    }
                    break;
                }
                case 'select-with-relation':
                    if (currentDropdownIndex > changedDropdownIndex && changedDropdownIndex > -1) {
                        break;
                    }

                    this.filtersDictionary[filter.name] = filter.value;
                    currentDropdownIndex++;
                    break;
                case 'select':
                    this.filtersDictionary[filter.name] = filter.value;
                    break;
                case 'checkbox':
                    let newFilters = [];

                    if (filter.checked) {
                        newFilters = [...this.filtersArray, { 'name': filter.name, 'value': filter.value }];
                    } else {
                        newFilters = this.filtersArray.filter(item => item !== filter.value);
                    }
                    this.filtersArray = newFilters;
                    break;
                case 'search':
                    this.filtersDictionary[filter.name] = filter.value;
                    break;
                case 'range-slider':
                    const input = filter.querySelector('.input');
                    const paramName = filter.querySelector('input:checked').value;

                    if (input.getAttribute('isDirty') === 'false') {
                        break;
                    }

                    let value = input.dataset.realValue;

                    this.filtersDictionary[paramName] = value;
                    break;
                default:
                    break;

            }
        }

        if (shouldUpdate) {
            sessionStorage.setItem('filters array', JSON.stringify(this.filtersArray));
            sessionStorage.setItem('filters dictionary', JSON.stringify(this.filtersDictionary));
            this.triggerUpdateDebounced(filter.getAttribute('aria-controls'));
        }

    }

    updateFilterDebounced = debounce(this.updateFilter, 500, false);
}

export default Filters;
