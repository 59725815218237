import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../../form/input/Input';

import { CalculationContext } from '../../../../context/CalculationContext';

const InitialParameters = (props) => {
    const calculationContext = React.useContext(CalculationContext);

    return (
        <div className='calculator__input-wrapper'>

            {/* Select Country */}
            <Input label={props.translations.countryLabel} id='country' onChange={calculationContext.setCountry}>
                {props.countries.map((option) => <option selected={option.value.country === calculationContext.country} option={option.value.country}>{option.value.country}</option>)}
            </Input>

            {/* Select Construction Type */}
            <Input label={props.translations.applicationLabel} id='constructionType' onChange={calculationContext.setApplication}> {props.applications.map((option) => <option selected={option.value.application === calculationContext.application} option={option.value.application}>{option.value.application}</option>)}</Input>

            {/* Select New building */}
            <div className="form-item form-item--select ">
                <label htmlFor={'newBuilding'} className="form-item__label">
                    {props.translations.buildingTypeLabel}
                </label>
                <select id={'newBuilding'} onChange={(e) => {
                    const isNewBuilding = e.target.value === props.translations.newBuildingLabel;
                    calculationContext.setNewBuilding(isNewBuilding);
                    calculationContext.setEnergyUsePerSquareMeter(isNewBuilding ? calculationContext.energyUseSqMetersNew : calculationContext.energyUseSqMetersExisting);
                }} className="select__select " name="Select" >

                    <option selected={calculationContext.newBuilding === true} value={props.translations.newBuildingLabel}>{props.translations.newBuildingLabel}</option>
                    <option selected={calculationContext.newBuilding === false} value={props.translations.existingBuildingLabel}>{props.translations.existingBuildingLabel}</option>

                </select>

            </div>

            {/* Select Size in Square Meters */}
            <div className="form-item  ">
                <label htmlFor="input-field" className="form-item__label">
                    {props.translations.sizeInSqmLabel}
                </label>
                <input id="input-field" value={calculationContext.sizeOfBuilding} type="number" className="input__input"
                    onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value) && value.trim() !== '') {
                            calculationContext.setSizeOfBuilding(Number(value));
                        }
                    }}
                />
            </div>
        </div>
    );
};

InitialParameters.props = {
    translations: PropTypes.any,
    countries: PropTypes.any,
    applications: PropTypes.any
};

export default InitialParameters;
