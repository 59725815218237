import React from 'react';
import { CalculationContext } from '../../../../context/CalculationContext';
import PropTypes from 'prop-types';
import Switch from '../../../../../switch/Switch';

const ProductAndServices = ({ props }) => {
    const calculationContext = React.useContext(CalculationContext);

    return (
        <div className='switch-layout'>

            <Switch
                onChange={calculationContext.setChillerHeatpump}
                checked={calculationContext.chillerHeatpump}
                label={props.translations.chillerHeatpumpLabel}
                id={props.translations.chillerHeatpumpLabel}

            />
            <Switch
                onChange={calculationContext.setHydronicOptimisation}
                checked={calculationContext.hydronicOptimisation}
                label={props.translations.hydronicOptimisationLabel}
                id={props.translations.hydronicOptimisationLabel}
                disabled={!calculationContext.chillerHeatpump}
            />
            <Switch
                onChange={calculationContext.setWaterflowOptimisation}
                checked={calculationContext.waterflowOptimisation}
                label={props.translations.waterflowOptimisationLabel}
                id={props.translations.waterflowOptimisationLabel}
                disabled={!calculationContext.chillerHeatpump}
            />
            <Switch
                onChange={calculationContext.setAirHandlingUnitWithBuiltInControls}
                checked={calculationContext.airHandlingUnitWithBuiltInControls}
                label={props.translations.airHandlingUnitWBuiltinControlsLabel}
                id={props.translations.airHandlingUnitWBuiltinControlsLabel}
            />
            <Switch
                onChange={calculationContext.setAHUWatertempOptimisation}
                checked={calculationContext.aHUWatertempOptimisation}
                label={props.translations.waterTemperatureOptimisationLabel}
                id={props.translations.waterTemperatureOptimisationLabel}
                disabled={!calculationContext.chillerHeatpump || !calculationContext.airHandlingUnitWithBuiltInControls}
            />
            <Switch
                onChange={calculationContext.setDCVAirOptimisation}
                checked={calculationContext.dCVAirOptimisation}
                label={props.translations.dcvAirOptimisationLabel}
                id={props.translations.dcvAirOptimisationLabel}
            />
            <Switch
                onChange={calculationContext.setDCVWatertempOptimisationRoom}
                checked={calculationContext.dCVWatertempOptimisationRoom}
                label={props.translations.waterTemperatureOptimisationRoomLabel}
                id={props.translations.waterTemperatureOptimisationRoomLabel}
                disabled={!calculationContext.chillerHeatpump || !calculationContext.dCVAirOptimisation}
            />
            <Switch
                onChange={calculationContext.setAnalyticsandVisualisation}
                checked={calculationContext.analyticsandVisualisation}
                label={props.translations.analyticsAndVisualisationLabel}
                id={props.translations.analyticsAndVisualisationLabel}
            />

        </div>
    );
};

ProductAndServices.props = {
    translations: PropTypes.any

};

export default ProductAndServices;
